.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-blink {
  animation: blink-animation 0.9s ease-in infinite;
}

@keyframes blink-animation {
  0%,
  55% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
.top-fade {
  position: relative;
}
.top-fade::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 9999999;
}

/* iOS not-zooming workaround */
input {
  font-size: 16px;
}

/* Jumping dots */
div#wave {
  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 3px;
    background: #9c9c9c;
    animation: wave 0.8s linear infinite;

    &:nth-child(2) {
      animation-delay: -0.6s;
    }

    &:nth-child(3) {
      animation-delay: -0.4s;
    }
  }
}

@keyframes wave {
  0%,
  70%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-6px);
  }
}

/* Hide scrollbar in chat, but keep scrollable */
.custom-scrollbar::-webkit-scrollbar {
  display: none;
}
.custom-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.noise-gradient-bg {
  background-repeat: repeat, no-repeat; /* Repeats the noise image */
}
/* Animated Gradient Border for Assistant's Messages */
.animated-border-wrapper {
  position: relative;
  padding: 1px; /* Adjust the padding to control border thickness */
  border-radius: 0.6rem; /* Match the border radius of the inner div */
  background: linear-gradient(
          45deg,
          #3b82f6, /* blue-500 */
          #1d4ed8, /* blue-700 */
          #14b8a6, /* teal-600 */
          #0d9488  /* teal-700 */
  );
  background-size: 300% 300%;
  animation: gradient-animation 4s ease infinite;
}

.animated-border-wrapper::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: inherit;
  background: inherit;
  z-index: -1;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* Marker-Like Animated Border for Assistant's Messages */
.marker-border-wrapper {
  position: relative;
  padding: 1px; /* Adjust the padding to control border thickness */
  border-radius: 0.6rem; /* Match the border radius of the inner div */
  background: linear-gradient(
          45deg,
          #3b82f6, /* blue-500 */
          #1e40af, /* blue-700 */
          #14b8a6, /* teal-600 */
          #0d9488  /* teal-700 */
  );
  background-size: 400% 400%;
  animation: gradient-border 8s ease infinite;
  overflow: hidden;
}

.marker-border-wrapper::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: inherit;
  background: inherit;
  z-index: -1;
  filter: blur(4px);
  opacity: 0.7;
}

@keyframes gradient-border {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Existing Animations (Ensure These Are Present) */
@keyframes fadeInBlur {
  0% {
    opacity: 0;
    filter: blur(4px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.animate-fade-in-blur {
  animation: fadeInBlur 0.6s ease-out forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slide-in 0.5s ease-out forwards;
}


/* Custom Scrollbar Styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f9fafb;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 4px;
  border: 2px solid #f9fafb;
}


/* Animated Underline */
.underline-animation {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #3b82f6, #14b8a6);
  background-size: 200% 200%;
  animation: underline-sweep 1s linear infinite;
  margin-top: 4px;
  border-radius: 1px;
}

@keyframes underline-sweep {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
